








































.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.advanced-course-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.advanced-course-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.advanced-course-text {
  font-size: 14px;
  line-height: 22px;
}

#highchart-content {
  height: 330px;
  width: 100%;
}

.iframe-shield {
  background: transparent;
  width: 415px;
  height: 350px;
  z-index: 99999;
  top: 0;
  position: absolute;
  margin-top: 40px;
  display: none;
}

#iframe-map {
  width: 100%;
  height: 80%;
  position: relative;
  float: left;
  border: none;
}
.draggable {
  cursor: move;
}

.select-term-style {
  bottom: 4px !important;
  position: absolute;
}
.advanced-course-select {
  margin-top: 20px !important;
}
.iframe-container {
  width: 100%;
  position: relative;
  float: left;
  height: 300px;
}
