


































































































































































































































































































































@use "@material/linear-progress";
@include linear-progress.core-styles;
.master-eurekers-top {
  display: flex;
  align-items: center;
}
.main-progress-bar {
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.back-progress-bar {
  background: grey;
}
.front-progress-bar {
  background: #0797b9;
}

.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.master-eurekers-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.master-eurekers-text {
  font-size: 14px;
  line-height: 22px;
}

.master-eurekers-content {
  display: flex;
}
.draggable {
  cursor: move;
}
.level-eurekers {
  color: #00add8;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 18px;
  margin: 0 0 14px;
  text-transform: uppercase;
}

.basic-btn-container {
  margin-top: 15px;
  margin-right: 10px;
}

.advanced-btn-container {
  margin-top: 10px;
}

.basic-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: #00add8;
}

.advanced-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: #00add8;
}

.basic-btn:hover {
  color: #00c5f5;
}

.advanced-btn:hover {
  color: #00c5f5;
}

.basic-exercises-container {
  z-index: 99999;
  background-color: white;
  width: 200px;
  overflow: scroll;
  height: 180px;
}

.advanced-exercises-container {
  z-index: 999999;
  background-color: white;
  width: 200px;
}

.b_url {
  font-size: 12px;
  margin-left: 10px;
}

.b_url:hover {
  color: #25d4ff;
}

.a_url {
  font-size: 12px;
  margin-left: 10px;
}

.a_url:hover {
  color: #25d4ff;
}

hr {
  border: 1px solid #00c5f5;
  border-top: none;
  width: 200px;
  margin-left: 10px;
}
