



















































































































































































.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: var(--color-surface);
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}
.widget:hover {
  border-top: 2px solid #00add8;
}
.twin-souls-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.twin-souls-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.twin-souls-text {
  font-size: 14px;
  line-height: 22px;
  color: var(--color-text-primary);
}
.twin-souls-icons * {
  color: var(--color-text-primary);
}
.draggable {
  cursor: move;
}
.twin {
  width: 34%;
  float: left;
  margin-top: 5px;
  margin-left: 5%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.div-interior{
  height: 90%;
  width: 90%;
  padding: 15px;
}

.div-exterior{
  height: 200px;
  width: 162px;
  display: none; /* Initially hide the div-exterior */
  
}

.twin-text{
  font-size: 10px;
  color: white;
}

.twin-title{
  font-size: 14px;
  color: white;
}


.div-exterior-hovered {
  display: block; /* Show the div-exterior when hovered */
  background-color: #004556;
}
