




















































#add-widget img {
  width: 100%;
}

#add-widget{
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: var(--color-surface);
}

@media(max-width:450px){
  #add-widget {
    border-radius: 5px;
    height: 327px;
    padding: 5px;
    background-color: white;
    width: 420px;
  }

  #add-widget img{
    width: 100%;
    height:100%;
    max-height: 327px;
    max-width: 420px;
  }
}

.video-class{
  height: 350px;
  width: 420px;
  overflow-x: hidden;
}

#add-widget:hover{
  cursor: pointer;
}
