








































































































































































































































.widget {
    border-radius: 5px;
    height: 350px;
    width: 420px;
    padding: 5px;
    background-color: white;
    position: relative;
    overflow: scroll;
}

@media (max-width: 450px) {
    .widget {
        border-radius: 5px;
        padding: 5px;
        background-color: white;
        width: calc(100% - 10px);
    }
}

.title {
    color: #00add8;
}

.widget:hover {
    border-top: 2px solid #00add8;
}

.portfolio-profitability {
    margin-left: 15px;
}

.portfolio-name {
    margin: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 37px;
}

.blue-class {
    color: #00add8;
}

.portfolio-label {
    font-size: 14px;
    font-weight: bold;
}

.charge-bar-container {
    width: 280px;
    height: 18px;
    background-color: #3a3842;
    border-radius: 3px;
    border: 3px solid #3a3842;
    overflow: hidden;
}

.charge-bar {
    height: 100%;
    background-color: #ffffff;
}

.portfolio-tickers-container {
    background-color: #f4f4f8;
    padding-top: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 15px;
}

.tickers-container {
    margin-top: 20px;
}

.arrow {
    margin-left: 150px;
}

.efective_invested {
    font-size: 9px;
}

.capital-info {
    text-align: center;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.invested {
    margin-left: 128px;
}

.blue-type {
    background-color: #71b5df;
    border-radius: 6px;
    font-size: 11px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    width: 33px;
    text-transform: uppercase;
    text-align: center;
}

.yellow-type {
    background-color: #ddb57d;
    border-radius: 6px;
    font-size: 11px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    width: 33px;
    text-transform: uppercase;
    text-align: center;
}

.green-type {
    background-color: #63ab76;
    border-radius: 6px;
    font-size: 11px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    width: 33px;
    text-transform: uppercase;
    text-align: center;
}

.ticker-info-container {
    display: flex;
    justify-content: space-between;
}

.ticker-name-container {
    width: 30px;
}

.percentage {
    width: 47px;
}
