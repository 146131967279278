









































































.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

    .video-class{
      height: 280px;
      width: 400px;
      overflow-x: hidden;
    }

    .widget:hover{
      border-top: 2px solid #00add8;
    }

    .your-photo-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .your-photo-top-box {
        display: flex;
        align-items: center;
    }

    .material-icons {
        margin-left: 5px;
    }
    .material-icons:hover{
        cursor: pointer;
    }

    h4 {
        color: #00add8;
    }

    a {
        text-decoration: none;
        color: #00add8;
        font-weight: bold;
    }

    .blue {
        color: #00add8;
    }

    .blue:hover{
        border-bottom:1px solid #00add8;
    }

    .your-photo-text {
        font-size: 14px;
        line-height: 22px;
    }
    .draggable{
      cursor: move;
    }

    h3{
      color: #00add8;
    }
