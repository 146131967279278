






















































































































































.widget {
  padding: 8px;
  background-color: black;
  padding: 30px;
  height: 330px;
  width: 370px;
  border-radius: 5px;
  padding-top: 0px;
  overflow: hidden;
  position: relative;
}

#platinum-values {
  background-color: #20202B !important;
}

#platinum-values p, #platinum-values span {
  color: white !important;
}

@media (max-width: 450px) {
  .widget {
    padding: 8px;
    background-color: black;
    padding: 30px;
    height: 330px;
    width: calc(85% - 10px);
    border-radius: 5px;
    padding-top: 0px;
    overflow: hidden;
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.scroll {
  overflow-y: scroll;
  height: 250px;
  margin-top: 10px;
}

.platinium-best-values-title {
  color: white;
  font-weight: bold;
  font-size: 17px;
  margin: 0;
}

.material-icons {
  color: white;
}

.material-icons:hover {
  cursor: pointer;
}

.platinium-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.platinium-text {
  color: white;
  text-decoration: none;
}

.platinium-top-box {
  display: flex;
  align-items: center;
}

.platinium-title {
  color: white;
  text-align: center;
  margin: 20px 0 8px;
  font-weight: bold;
}

.align-content {
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 11px 0 7px;
}

p {
  color: white;
  margin: 0;
  font-size: 14px;
}

.nom-size {
  width: 30%;
}

.ticker-size {
  width: 25%;
  text-align: end;
}

.number-size {
  width: 25%;
  font-weight: 900;
  text-align: end;
  line-height: 12px;
}

.text-small {
  font-size: 11px;
  margin: 0;
  font-weight: 400;
}

.buttons {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 23%;
  font-weight: bold;

  span {
    color: white;
  }
}

.active {
  opacity: 1;
}

.draggable {
  cursor: pointer;
}
div:-moz-drag-over {
  background-color: none;
}
