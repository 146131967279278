













































































































































.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }

  .alerts_container {
    height: 280px;
  }
}
.alerts_container {
  height: 280px;
}
.alert {
  border: 1px solid #00add8;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.best-sectors-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.best-sectors-top-box {
  display: flex;
  align-items: center;
}

.draggable {
  cursor: move;
}

.registry-text {
  margin-top: 0px !important;
}
.pending-alarms-container {
  margin-left: 5px;
  border: 1px solid lightgrey;
  border-left: 5px solid #42a0c9;
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
}

.registry-date {
  width: 77px;
  height: 24px;

  /* Subtitle 2 */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  /* Tema Claro/02. Primary/900 */

  color: #000010;

  padding: 10px 0 0 15px;
}

.registry-text {
  width: 341px;

  /* Caption */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  letter-spacing: 0.4px;

  /* Tema Claro/02. Primary/900 */

  color: #000010;

  padding: 5px 0 15px 15px;
}
